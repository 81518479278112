<template>
  <RecordViewWrapper>
    <sdPageHeader title="Data Konfirmasi Rakor PLTU">
      <template #subTitle>
        <sdButton
          class="btn-add_new"
          size="default"
          type="primary"
          v-if="checkPermission('create permintaan internal data konfirmasi rakor pltu')"
        >
          <router-link to="/internal/konfirmasi-rakor-pltu-add">
            <sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table :pagination="false" :dataSource="dataSource" :columns="columns.data" />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove konfirmasi rakor {{ modalState.month }} {{ modalState.year }}?
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';

const columns = reactive({
  data: [],
});

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const modalState = reactive({
      isLoading: false,
      visible: false,
      month: '',
      year: null,
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'pasokan-batubara').then(() => {
        columns.data = [];

        months.forEach((month, key) => {
          columns.data.push(
            {
              title: month,
              dataIndex: key,
              key: key,
              align: 'right',
            },
            {
              title: 'Status',
              dataIndex: 'status_' + key,
              key: 'status_' + key,
            },
            {
              title: '',
              dataIndex: 'action_' + key,
              key: 'action_' + key,
            },
          );
        });
      });
    };

    const handleDelete = (id, month, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus data konfirmasi rakor PLTU ${months[month - 1]} ${year}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'pasokan-batubara',
            getData: () => {
              dispatch('axiosCrudGetData', 'pasokan-batubara');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud.value.map((data, key) => {
            const values = {
              key: key + 1,
            };

            if (data && data.length) {
              data.forEach(dl => {
                let color;

                switch (dl.status) {
                  case 'CRTD':
                    color = 'blue';
                    break;
                  case 'UPDT':
                    color = 'orange';
                    break;
                  case 'REL':
                    color = 'green';
                    break;
                  case 'UNREL':
                    color = 'volcano';
                    break;
                  case 'DEL':
                    color = 'red';
                    break;
                }

                values[dl.month - 1] = dl.value;

                values['status_' + (dl.month - 1)] = <a-tag color={color}>{dl.status}</a-tag>;

                let update = checkPermission('update permintaan internal data konfirmasi rakor pltu') ? (
                  <router-link class="edit" to={`/internal/konfirmasi-rakor-pltu-edit/${dl.id}`}>
                    <sdFeatherIcons type="edit" size={14} title="Ubah" />
                  </router-link>
                ) : null;

                let approve = checkPermission('approve permintaan internal data konfirmasi rakor pltu') ? (
                  <a class="edit" onClick={() => handleApprove(dl.id, dl.month, dl.year)}>
                    <sdFeatherIcons type="check-square" size={14} title="Approve" />
                  </a>
                ) : null;

                let del = checkPermission('delete permintaan internal data konfirmasi rakor pltu') ? (
                  <router-link class="delete" onClick={() => handleDelete(dl.id, dl.month, dl.year, name)} to="#">
                    <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
                  </router-link>
                ) : null;

                let unapprove = checkPermission('approve permintaan internal data konfirmasi rakor pltu') ? (
                  <a class="edit" onClick={() => handleUnapprove(dl.id, dl.month, dl.year)}>
                    <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
                  </a>
                ) : null;

                values['action_' + (dl.month - 1)] =
                  dl.status != 'REL' ? (
                    <div>
                      {update}
                      {approve}
                      {del}
                    </div>
                  ) : (
                    { unapprove }
                  );
              });
            }

            return values;
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'pasokan-batubara', filter: formState.searchItem });
    };

    const handleApprove = (id, month, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve konfirmasi rakor ${months[month - 1]} ${year}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-pasokan-batubara',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const handleUnapprove = (id, month, year) => {
      formModalState.id = id;
      modalState.month = months[month - 1];
      modalState.year = year;
      modalState.visible = true;
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-pasokan-batubara/' + formModalState.id, { alasan: formModalState.alasan }).then(
        () => {
          modalState.visible = false;
          modalState.isLoading = false;
          formModalState.id = null;
          formModalState.alasan = '';

          getData();
        },
      );
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
      labelCol,
      wrapperCol,
      handleApprove,
      handleUnapprove,
      modalRules,
      modalState,
      formModalState,
      handleCancel,
      doUnapprove,
      checkPermission,
    };
  },
};

export default ViewPage;
</script>
